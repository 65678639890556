/* ----------------------------------------- */
/* About us page css  */
/* ----------------------------------------- */




.background_banner {
    background: url('../Components/Images/banner\ \(3\).png') no-repeat center center;
    background-size: cover;
    color: white;
    /* text-align: center; */
    padding: 100px;
    height: 50vh;
}

.contact_banner {
    background: url('../Components/Images/banner\ \(4\).png') no-repeat center center;
}

.blog_banner {
    background: url('../Components/Images/banner\ \(5\).png') no-repeat center center;
}

.main_college_banner {
    background: url('../Components/Images/banner\ \(2\).png') no-repeat center center;

}

.cousepage_banner {
    background: url('../Components/Images/banner\ \(4\).png') no-repeat center center;
}

.background_banner h2 {
    font-size: 35px;
    font-family: "DM Serif Display", serif;
    font-weight: bolder;
    padding: 5rem 0 0.5rem 0;

    margin: 0;
}

.background_banner p {

    font-family: "DM Serif Display", serif;
}


/* Small devices (phones, 576px and up)-------------------- */
@media only screen and (max-width: 768px) {

    .background_banner {
        background: url('../Components/Images/banner\ \(3\).png') no-repeat center center;
        background-size: cover;
        color: white;
        /* text-align: center; */
        padding: 15rem 0 0 15px;
        height: 70vh;
    }

    .background_banner h2 {
        font-size: 20px;
        font-family: "DM Serif Display", serif;
        font-weight: bolder;
        padding: 0 0 0 0;
        margin: 0;
    }

    .background_banner p {
        font-size: 15px;
    }


}


/* Review page css  */

.mybackground {
    background-image: url(../Components/Images//reviewbg\ \(3\).png);
    height: 100vh;

}

.reviw_contenttext {
    padding: 12rem 0 0 0;
    max-width: 900px;
    margin: auto;
    justify-content: center !important;
}

.guest_text {
    font-size: 20px;
    font-weight: 400;
}

.reviw_contenttext {
    color: white;
    font-weight: 800;
    font-size: 40px;
    letter-spacing: 1px;
}

.review_num {
    color: #ff7900;
    font-weight: 800;
    font-size: 38px;
}

.review_para {
    font-size: 15px;
    font-weight: normal;
}






















/* ------------------------------------------- */
/* Blog page Css  */
/* ------------------------------------------- */



/* .innerpage_cont {
    padding: 10rem 0 0 0;
} */

.blog_btn {
    color: #103b38;
}



/* ---------------------------------------------------------- */
/* contact us css  */



.cont_card {
    background-color: #f0f8ff;
    height: 200px;
}

.cont_card p {
    color: #000;
    font-size: 17px;
    font-weight: 500;
}

.icon_img {
    width: 20%;
}

.contact_icon {
    color: #ff7900 !important;
}

.contact_crd_title {
    font-size: 28px !important;
    padding: 0.5rem 0 0 0 !important;
    color: #ff7900;
}

.Form_container {
    z-index: 1000 !important;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: rgba(209, 209, 209, 0.24) 0px 3px 8px;
}


.form_left_content {
    z-index: 1000 !important;
    padding: 0.5rem 0 0.5rem 0 !important;


}

.Form_left_head {
    font-size: 20px !important;
    font-weight: bold !important;
    color: #000 !important;
    padding: 1.7rem 0 0 1rem !important;
}

.form_left_sm_crd {
    height: 130px !important;
    padding: 10px !important;
}

.form_left_sm_title {
    font-size: 13px !important;
    padding: 0.5rem 0 0 0 !important;
    font-weight: 500 !important;
}

.text_cont {
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 0 0 0.5rem 0;
    margin: 0 0 0.5rem 0;

}


.icon {
    width: 80px;
    height: auto;
}


.text-container {
    display: flex;
    flex-direction: column;
    /* Stacks text vertically */
}


.Form_container .heading {
    font-size: 2rem;
    margin-bottom: 1px;
    font-weight: bolder;
    color: #ff7900;
}


.Form_container .heading2 {
    font-size: 1rem;
    margin-bottom: 25px;
    font-weight: bolder;
    color: #000;
    text-align: left;
}

.form {
    max-width: 700px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

/* .row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
} */

.input-field {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
}

.input-field label {
    position: absolute;
    color: #0f0f0f;
    pointer-events: none;
    background-color: transparent;
    left: 25px;
    transform: translateY(0.6rem);
    transition: all 0.3s ease;
}

.input-field input,
.input-field select {
    width: 100%;
    padding: 10px 15px;
    font-size: 1rem;
    border-radius: 3px;
    border: solid 1px #8d8d8d;
    letter-spacing: 1px;
}

.input-field input:focus,
.input-field input:valid {
    outline: none;
    border: solid 1px #ff7900;
}

.input-field input:focus~label,
.input-field input:valid~label {
    transform: translateY(-51%) translateX(-10px) scale(0.8);
    background-color: #fff;
    padding: 0px 6px;
    color: #ff7900;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 80px;
}



.formbtn {
    float: right !important;
}

.mybtn:active {
    box-shadow: inset 0px 4px 5px #ff7900, inset 0px -4px 5px #ff7900;
    transform: scale(0.995);
}

.passicon {
    cursor: pointer;
    font-size: 1.3rem;
    position: absolute;
    top: 6px;
    right: 8px;
}

.close {
    display: none;
}



/* Responsive css  */
/* Small devices (phones, 576px and up)-------------------- */
@media only screen and (max-width: 600px) {
    .icon {
        width: 20%;
    }

    .Form_container .heading {
        font-size: 1.1rem;
        margin-bottom: 1px;
        font-weight: bolder;
        color: #ff7900;
    }


    .Form_container .heading2 {
        font-size: 0.8rem;
        margin-bottom: 25px;
        font-weight: bolder;
        color: #000;
        text-align: left;
    }
}


/* --------------------------------------------- */
/* colleges main page css  */
/* --------------------------------------------- */

.leftimg {
    width: 80%;
    border: 4px solid #103b38;
    border-radius: 2rem;
    box-shadow: 0 2rem 5rem rgba(9, 2, 2, 0.15) !important;
}

.main_page_container {
    padding-top: 4rem;
}

.background-box {
    background-color: #e2ebfa;
    /* Light gray background */
    padding: 18px;
    border-radius: 8px;
    text-align: center;
    /* Centering text */
}

.background-box p {
    font-weight: bold;
    font-size: 1.5rem;
    color: #103b38;
    text-align: start;
    margin-top: 0;
    font-family: var(--font-primary);
}

.list-unstyled li {
    margin: 12px 0 0 18px;
    font-family: var(--font-primary);
}


/* table css here  */

.tablebody {
    font-family: var(--font-primary);
}




/* ------------------------------ */
/* termpage css  */
/* ------------------------------ */

.termpage {
    padding: 10rem 0 0 0;
}

@media only screen and (max-width: 768px) {
    .termpage {
        padding: 15rem 0 0 0;
    }
}

/* not found css here  */
.display {
    text-align: center;
    padding: 150px 20px;
    margin: 0 auto;
    background-color: #000;
}

.display h1 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #e63946;
}


.page_not_found h2 {
    color: white;
    padding: 5rem 0 0 0;

}

.page_not_found p {
    font-size: 15px;
    color: #ffffff;

}
@media (max-width: 600px) {
    .display {
        text-align: center;
        padding: 250px 20px;
        margin: 0 auto;
        background-color: #000;
    } 
}